.stockTicker {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #43576d;
  /* border-radius: 10px; */
  /* padding: 20px; */
  /* margin-top: 20px; */
  color: white;
}

.stockTicker.rebrand {
  background-color: #000;
}

.recharts-cartesian-axis-tick {
  font-size: 1.4rem;
  font-family: Roboto, sans-serif;
  color: white;
}

.green {
  color: #4caf50;
}

.red {
  color: #f44336;
}

.tickerText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  width: auto;
  margin-top: 20px;
}

.userInvestments {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translateX(-50%) translateY(-50%);
  text-align: right;
  font-size: 2.2rem;
  font-weight: bold;
  white-space: nowrap;
  font-family: "Roboto", sans-serif, monospace;
  margin-bottom: 20px;
  min-width: 320px;
}

.userInvestments > span {
  white-space: pre;
}

.userInvestments.rebrand > table {
  background-color: #282828;
}

.userInvestments table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2c4157;
  border-radius: 5px;
}

.userInvestments th {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  background-color: #2c4157;
  color: #fff;
  border-bottom: 2px solid #43576d;
  border-radius: 5px;
}

.userInvestments.rebrand th {
  background-color: #282828;
  border-bottom: 2px solid rgba(255, 152, 20, 1);
}

.userInvestments td {
  padding: 10px 15px;
  font-size: 18px;
}

.accountBalance {
  font-size: 2rem;
  width: 100%;
}

.investmentName {
  text-align: left;
  font-weight: bold;
  color: #bbb;
}

.atlas,
.speef {
  display: inline-block;
  padding: 10px 15px;
  background-color: #2c4157;
  border-radius: 5px;
  margin: 5px;
}

.atlas.rebrand,
.speef.rebrand {
  background-color: #575757;
}

.triangle {
  font-size: 2rem;
}

.panels {
  display: grid;

  grid-template-columns: [col1] 20% [col2] 20% [col3] 20% [col4] 20% [col5] 20% [end];
  grid-template-rows: [row1] 25% [row2] 25% [row3] 25% [row4] 25% [row5] 25% [row6] 25% [row7] 25% [end];
  overflow-x: hidden;

  flex-direction: row;
  justify-content: flex-start;
  width: auto;
}

.panels > div {
  border-width: 1px;
  border-color: black;
  border-style: solid;
  max-height: 1000px;
}

.cueList {
  display: grid;
  grid-column-start: auto;
  grid-column-end: auto;
}

.quizPanel {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row: 0;
  align-content: center;
}

.notificationPanel {
  grid-column-start: auto;
  grid-column-end: auto;
  grid-row: 0;
  align-content: center;
}

.premadePostPanel {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 8;
  max-height: 900px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.premadeMessagePanel {
  grid-column-start: 5;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 8;
  max-height: 900px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sendNotification > input {
  width: 97.5%;
  align-self: center;
}

.addPost > input {
  width: 97.5%;
}

.addPost > input {
  width: 97.5%;
}

.dragDropData > label {
  min-width: 10%;
  max-width: 100%;
  height: 100%;
}

.startStopButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.boxLabelPair {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  padding: 8px;
  border: 2px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.boxLabelPair > span {
  font-size: 15px;
  margin-right: 10px;
}

.boxLabelPair > input {
  /* display: none; */
  background-color: white;
}

.boxLabelPair.checked {
  color: white;
  font-weight: 400;
  background-color: green;
}

.boxLabelPair.unchecked {
  /* border-color: red; */
  background-color: white;
}

div.quizButton > input {
  width: 100%;
}

.boxLabelPair > input {
  order: 0;
  justify-content: flex-start;
  width: auto;
  max-width: 15px;
}

.oneMessage {
  display: flex;
}

.submitButton {
  width: 100%;
}

label[for="file"] {
  height: 100%;
  padding: 0px;
}

.post {
  max-width: 100%;
}

.deleteMessage {
  cursor: pointer;
  color: red;
  font-size: large;
  margin-left: 10px;
}

.addMessagePlus {
  cursor: pointer;
  color: green;
  font-size: large;
  margin-left: 10px;
}

.premadeMessageArchetypeText {
  color: white;
}

/* .setDisplayName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    overflow: hidden;
} */

.sendTBAMessage {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: auto;
  overflow: hidden;
}

.tbaMessageInputs {
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
}

.testPanel > input {
  width: 100%;
}

.dragDropData {
  height: 50%;
}

.signOutButton {

    z-index: 6;

    /* font-family: 'Oswald', sans-serif; */
    overflow: hidden;
    opacity: 1;
    text-decoration: none;
    float: right;
    padding: 6px;
    color: black;
    background-color: white;
    overflow: hidden
}


.menu {
    display: flex;
    flex-direction: column;
    transition: left 0.7s;
    color: white;
    backdrop-filter: blur(3px);
    overflow: hidden;
}

.enabled.menu {
    height: 100%;
    width: 80%;
    position: absolute;
    z-index: 5;
    left: 0%;
}

.disabled.menu {
    height: 100%;
    width: 80%;
    position: absolute;
    z-index: 5;
    left: -200%;
}

.menu>span {
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
}

.menu>span.verificationMessage {
    position: absolute;
    padding-top: 40%;
    top: 0px;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 70%;
}
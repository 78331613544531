.appView {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  height: 100%;
  overflow: hidden;
  background-color: black;

  /* "Can we add a frame around the outside edge of the screen?" */
  /* don't like this but it was requested */

  /* border-width: 10px;
    border-style: solid;
    border-color: #43576d;
    box-sizing: border-box; */
}

.contentWindow {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.buttonBar {
  display: flex;
  flex-direction: row;
  z-index: 5;
  height: 3em;
}

g {
  color: black;
  height: 1em;
}

#top-divider {
  width: 100%;
  margin: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  color: white;
}

.buttonBar > .bottomPanelButton {
  margin: 0px;
  color: white;
  border-radius: 2px;
  width: 20%;
}

.button-divider {
  margin: 0px;
  color: white;
  border-radius: 2px;
}

.buttonBar > button {
  width: 100%;
  background-color: black;
  color: white;
  border: 0px;
}

.buttonIcons {
  width: 20px;
  font-size: 20px;
}

.appView.preRebrand {
  background-color: #202a35;
}

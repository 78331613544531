:root {
  --font-size-multiplier: 1.2; /* Adjust this value to change the overall font size */
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.introMessage {
  background: #adadad;
  color: black;
  text-align: left;

  border-radius: 20px;

  padding-left: 0px;
  padding-right: 0px;

  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;

  justify-content: right;
  width: fit-content;
}

.introMessage > p {
  padding-left: 10px;
  padding-right: 10px;

  margin-top: 5px;
  margin-bottom: 5px;

  font-size: calc(16px * var(--font-size-multiplier));
}

.messageContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.messageCreatedAt {
  font-size: calc(10px * var(--font-size-multiplier));
  color: white;
  position: relative;
  top: 0px;
  margin-top: 0px;
  text-align: right;
}

.preRebrand .messageCreatedAt {
  font-size: calc(10px * var(--font-size-multiplier));
  color: white;
  position: relative;
  top: 0px;
  margin-top: 0px;
  text-align: right;
}

.received > .messageCreatedAt {
  text-align: left;
}

div .message {
  flex: 0;
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
  margin-bottom: 2px;
  font-size: calc(16px * var(--font-size-multiplier));

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}

div .message.sent {
  color: white;
  text-align: right;

  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 0px;

  padding-right: 10px;
  padding-left: 10px;

  margin-bottom: 2px;

  background: #0b93f6;

  margin-right: 0px;

  width: fit-content;
  align-self: flex-end;
}

p .message.sent {
  align-self: flex-end;
}

div .message.received {
  background: #adadad;
  color: black;
  text-align: left;

  margin-left: 0px;
  margin-top: 0px;
  margin-right: 5px;

  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 0px;

  padding-left: 10px;
  padding-right: 10px;

  justify-content: right;
  width: fit-content;
}

div .message.received > p {
  margin-top: 10px;
  margin-bottom: 10px;
}

div .message.sent > p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.chat {
  height: 100%;
}

.chatContainer {
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: 1px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: black;
  padding-top: 45px;
}

.chatContainer.preRebrand {
  background-color: #43576d !important;
}

.sendMessageForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 50px; */
  overflow: hidden;
  background-color: #43576d;
  /* border-top: 1px solid #2f3336; */
  padding: 10px;
}

.sendMessage {
  flex-grow: 1;
  height: 40px;
  border: none;
  border-radius: 20px;
  padding: 0 15px;
  font-size: calc(16px * var(--font-size-multiplier));
  background-color: #202327;
  color: #ffffff;
}

.sendMessage::placeholder {
  color: #6e767d;
}

.sendMessageButton {
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
  background-color: #1d9bf0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sendMessageButton:hover {
  background-color: #1a8cd8;
}

.sendMessageButton:disabled {
  background-color: #1d9bf0;
  opacity: 0.5;
  cursor: not-allowed;
}

.sendMessageButton svg {
  width: 20px;
  height: 20px;
}

.sendMessageButton.preRebrand {
  background-color: white;
}

input {
  display: flex;
  flex: 1;
}

.teamname {
  font-size: calc(10px * var(--font-size-multiplier));
  color: black;
  position: relative;
  top: 0px;
  left: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: right;
  overflow: hidden;
  max-width: 98%;
}

.preRebrand .teamname {
  font-size: calc(10px * var(--font-size-multiplier));
  color: white;
  position: relative;
  top: 0px;
  margin-top: 0px;
}

.received > .teamname {
  text-align: left;
  margin-left: 0px;
}

.sent > .teamname {
  text-align: right;
  margin-right: 0px;
}

.rebrand.teamname {
  color: white;
}

.justice-warrior {
  background-color: #eb00ff !important;
}

.astute-logician {
  background-color: #ebff00 !important;
}

.freedom-steward {
  background-color: #008b01 !important;
}

.stalwart-nationalist {
  background-color: #006fd8 !important;
}

.social-guardian {
  background-color: #15bd1d !important;
}

.top-bar.preRebrand {
  height: 40px;
  width: 100%;
  background-color: #2c4157;
  border-bottom: 3px solid rgba(255, 152, 20, 1);
}

.top-bar {
  height: 40px;
  width: 100%;
  background-color: black;
  border-bottom: 3px solid rgba(255, 152, 20, 1);
  z-index: 5;
}

.logo-container > svg {
  height: 30px;
  transform: translateY(15%);
}

.logo-container.rebrand {
  overflow: hidden;
}

.logo-container.preRebrand {
  height: 60px;
  display: inline-block;
  transform: scale(2) translateY(15px);
}

.logo-container .logo {
  height: 100%;
  width: auto;
  color: white;
}

.logo-text {
  color: white;
  font-weight: 600;
  transform: translateY(-2%);
}

.chatRoomDeletePost {
  cursor: pointer;
  color: red;
  font-size: large;
  margin-left: 2px;
}

.giantSliderContainer {
  position: absolute;
  background-color: #43576d;
  padding-right: 10px;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow-x: hidden;
}

input.giantSlider {
  /* height: %; */
  /* margin-top: 50%; */
  width: 90%;
  margin-right: 0px;
  margin-left: 0px;
  /* margin-top: 100%; */
  -webkit-appearance: none;
  appearance: none;
  align-self: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.enabled.giantSliderContainer {
  top: 0px;
  position: absolute;
  z-index: 6;
  left: 0%;
}

.disabled.giantSliderContainer {
  top: -200%;
  position: absolute;
  z-index: 5;
  transition: top 0.7s;
}

/* change if you have the time */
.giantSliderContainer img {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  max-width: 300px;
}

.avatarImg {
  display: block;
  position: absolute;
  /* filter: blur(3px); */
  /* filter: pixelate(27px); */
  image-rendering: pixelated;
  width: 112px;
  height: auto;
  top: 17.1%;
  left: 50%;
  right: auto;
  z-index: 3;
  animation: flash 3s infinite;
  transform: translate(-50%, -50%) scale(1.45);
}

.sliderValueLabel {
  padding-top: 2px;
  color: white;
  font-size: 1.5rem;
}

.slider-pic {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}

.slider-pic:hover {
  opacity: 1;
}

.slider-pic::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.slider-pic::-moz-range-thumb {
  width: 50px;
  height: 50px;
  border: 0;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.audienceSentimentMeter {
  color: white;
  position: relative;
  /* top: 50%; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  /* margin-top: 80%; */
  margin-bottom: 5%;
  font-size: 1.5rem;
}

.giantSliderStuff {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

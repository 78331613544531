.quizContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #43576d;
}

.question {
  text-align: center;
  text-wrap: pretty;

  margin-left: 10px;
  margin-right: 10px;

  padding-left: 5px;
  padding-right: 5px;

  padding-bottom: 12px;
  padding-top: 12px;

  margin-top: 35px;

  margin-bottom: 5px;

  background-color: #2c4157;
  color: white;
  font-weight: 600;
  opacity: 80%;
  border-radius: 4px;
}

.postResponseMessage {
  color: white;
  font-weight: 600;
  margin-top: 20px;
}

.question > img {
  padding: 0px;
  width: 90%;
  border-radius: 20px;
}

.responses {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.responsesGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.responses button:disabled {
  opacity: 50%;
}

.responses button:enabled {
  opacity: 80%;
}

.responses button {
  margin: 10px;
  color: rgba(255, 152, 20, 1);
  background-color: #2c4157;
  border-color: #2c4157;
  font-size: 23px;
  border-style: solid;
  border-radius: 4px;
  padding: 12px;
  font-weight: 600;
}

.timeLeftBar {
  min-width: 90%;
  height: 20px;
  transform: translateY(-2px);
  -webkit-appearance: none;
  appearance: none;
  border-width: 0px;
  border-radius: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #43576d;
}

.timeLeftBar::-webkit-progress-bar {
  background-color: #43576d;
  border-radius: 10px;
}

.timeLeftBar::-webkit-progress-value {
  background-color: rgba(255, 152, 20, 1);
  border-radius: 10px;
  transition: width 0.5s ease;
}

.timeLeftBar::-moz-progress-bar {
  background-color: rgba(255, 152, 20, 1);
  border-width: 0px;
  border-radius: 10px;
}

.quizScreenEffectDiv {
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: fixed;
  z-index: -10;
  opacity: 60%;
}

/* 
#vert_menu {
    overflow: hidden;
    width: 100%;
}

#vert_menu li {
    float: left;
}

#vert_menu a {
    padding: 8px 20px 8px 40px;
    float: left;
    text-align: center;
    text-decoration: none;
    font: normal 16px Myriad Pro, Helvetica, Arial, sans-serif;
    text-shadow: 0px 1px 0px #000;
    color: #e6e2cf;
    position: relative;
    text-shadow: 1px 0 0 #000;
    background: #525252;
    min-width: 181px;
    width: auto
}

#vert_menu a::after,
#vert_menu a::before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -19px;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 1em solid;
    right: -1em;
}

#vert_menu a::after {
    z-index: 2;
    border-left-color: #525252;
}
 */

.user-results-display {
  /* background-color: #2c4157; */
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.result-status {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
}

.result-status.correct {
  background-color: #4caf50;
  color: white;
}

.result-status.incorrect {
  background-color: #f44336;
  color: white;
}

.result-stats {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c4157;
  padding: 10px 15px;
  border-radius: 5px;
}

.stat-label {
  font-weight: bold;
  color: #bbb;
  text-align: left;
}

.stat-value {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
}

.progressBarContainer {
  position: relative;
  width: 100%;
  margin: 10px 0;
  justify-content: center;
}

.timeLeftText {
  position: absolute;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%) translateY(1px);
  text-align: center;
  width: 100%;
  color: white;
  /* color: rgba(255, 152, 20); */
  /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, */
  /* 1px 1px 0 #000; */
}

.fakeSocialButtonBar {
    display: flex;
    flex-direction: row;
}

.fakeSocialButtonBar>svg {
    width: 100%;
    height: 1em;
}

.verificationMessage {
    position: absolute;
    font-size: small;
    backdrop-filter: blur(1.5px);
    text-align: center;
    vertical-align: middle;
    width: 98%;
    overflow-x: hidden;
}
.powerPlayLanding {
  position: absolute;
  background-color: black;
  color: white;
  padding-right: 10px;
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: 9;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow-x: hidden;
}

.enabled.powerPlayLanding {
  top: 0px;
  position: absolute;
  left: 0%;
  pointer-events: all;
}

.disabled.powerPlayLanding {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.investmentPage {
  position: absolute;
  background-color: #43576d;
  color: white;
  padding-right: 10px;
  width: 100%;
  height: 100%;
  z-index: 8;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.enabled.investmentPage {
  top: 0px;
  position: absolute;
  left: 0%;
}

.disabled.investmentPage {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.hasInvestedButton {
  border-color: white;
}

.investmentButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  padding: 10px;
  color: rgba(255, 152, 20, 1);
  background-color: #2c4157;
  border: none;
  border-radius: 10px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;

  width: auto;
  align-self: center;
  transform: scale(150%);
}

.investmentButton:hover {
  opacity: 0.9;
}

.investmentButton img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.investmentButton span {
  text-align: center;
}

.investmentTitle {
  color: white;
  font-weight: 600;
  font-size: 30px;
  text-align: left;
  padding-left: 20px;
}

.investmentValue {
  color: white;
  font-weight: 600;
  font-size: 30px;
  text-align: left;
  padding-right: 20px;
}

.investmentSubtitle {
  font-size: 20px;
}

.totalBucks,
.roundScore {
  font-size: 2em;
  margin-bottom: 10px;
}

.powerplayEffectDiv {
  height: 100%;
  width: 100%;
  pointer-events: all;
  position: fixed;
  z-index: 100;
  opacity: 100%;
}

.powerPlayLogo {
  object-fit: contain;
  width: 100%;
  top: 75px;

  width: 50%;
  height: 132px;
  position: absolute;
  left: 25%;
  transform: scale(300%);
  object-fit: cover;
}

.interestPage {
  position: absolute;
  background-color: black;
  color: white;
  padding-right: 10px;
  width: 100%;
  height: 100%;
  z-index: 8;
  transition: top 0.7s;
  padding: 0px;
  overflow: hidden;
  /* hopefully this is fine, just remember that it's here */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.enabled.interestPage {
  top: 0px;
  position: absolute;
  left: 0%;
}

.disabled.interestPage {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.interestPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  justify-items: center;
}

.interestPage > button {
  height: 80%;
  width: 80%;
  align-self: center;
  margin: 20px;
  font-size: 18px;
  /* display: flex; */
}

.interestPage > button:disabled {
  /* background-color: red; */
  color: rgb(109, 109, 109);
  /* display: flex; */
}

.xGOVLanding {
  position: absolute;
  background-color: black;
  color: white;
  padding-right: 10px;
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: 8;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow-x: hidden;
}

.enabled.xGOVLanding {
  top: 0px;
  position: absolute;
  left: 0%;
  pointer-events: all;
}

.disabled.xGOVLanding {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.xGOVLandingSpan {
  width: 100%;
  height: 100%;
  font-size: 100px;
  transform: scaleX(20%);
}

.xGOVAvatarImg {
  display: block;
  position: absolute;
  /* filter: blur(1.3px); */
  width: 200px;
  bottom: 0px;

  left: 50%;
  right: auto;
  /* margin-left: auto;
    margin-right: auto;
    margin-top: auto; */
  transform: translate(-50%, -50%) scale(2);
}

.xGOVLogoContainer {
  position: relative;
  width: 100%;
  height: 100%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
}

/* .xGOVLogoContainer .logo-text {
  transform: translate(-50%, -17%);
  top: -50%;
  left: 25%;
  font-size: 50px;
  position: relative;
} */

.fireLanding {
  position: absolute;
  background-color: black;
  color: white;
  padding-right: 10px;
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: 8;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow: hidden;
}

.enabled.fireLanding {
  top: 0px;
  position: absolute;
  left: 0%;
  pointer-events: all;
}

.disabled.fireLanding {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.fireGif {
  bottom: 0px;
  position: absolute;
  /* transform: scaleY(4); */
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.americanFlagLanding {
  position: absolute;
  background-color: black;
  color: white;
  padding-right: 10px;
  pointer-events: all;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: top 0.7s;
  padding: 0px;
  /* hopefully this is fine, just remember that it's here */
  overflow: hidden;
}

.enabled.americanFlagLanding {
  top: 0px;
  position: absolute;
  left: 0%;
  pointer-events: all;
}

.disabled.americanFlagLanding {
  top: -200%;
  position: absolute;
  transition: top 0.7s;
}

.americanFlagGif {
  /* bottom: 200px; */
  position: absolute;
  /* transform: scaleY(4); */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(90deg) scale(1.45);
  overflow: hidden;
  width: auto;
  height: auto;
}

.hasInvestedShortText {
  font-size: 40px;
  font-weight: 600;
}

.hasInvestedTitle {
  font-size: 20px;
  font-style: italic;
}

.hasInvestedButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  padding: 20px;
  color: rgba(255, 152, 20, 1);
  background-color: #2c4157;
  border: none;
  border-radius: 10px;
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;

  width: auto;
  align-self: center;
  transform: scale(140%);
}

.xGOVLogoContainer {
  z-index: 10;
}

.hasInvestedImage {
  max-width: 200px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
}

.App {
  height: 100%;
  text-align: center;
}

.App-header {
  min-height: 100vh;
  background-color: #43576d;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  justify-content: flex-start;
}

.globalScreenEffectDiv {
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: fixed;
  z-index: 8000;
  opacity: 40%;
}

.App-link {
  color: #61dafb;
}

.cueListButton {
  position: fixed;
  z-index: 100;
  right: 67px;
  top: 0px;
  /* font-family: 'Oswald', sans-serif; */
  overflow: hidden;
  opacity: 1;
  text-decoration: none;
  float: right;
  padding: 6px;
  color: black;
  background-color: white;
  overflow: hidden;
}

.videoTeamViewButton {
  position: fixed;
  z-index: 100;
  right: 156px;
  top: 0px;
  /* font-family: 'Oswald', sans-serif; */
  overflow: hidden;
  opacity: 1;
  text-decoration: none;
  float: right;
  padding: 6px;
  color: black;
  background-color: white;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 100%;
}

.surveyComponentContainer {
  height: 100%;
}

.liveFeed {
  left: 0px;
  top: 0px;
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.smallVideo {
  /* left: 40px;
  top: 40px; */
  position: fixed;
  height: 100px;
  width: 100px;
  margin: 0px;
  padding: 0px;
  z-index: 9001;
}

.errorText {
  color: #d9534f; /* A more professional red color */
  font-size: 16px; /* Slightly smaller font size */
  margin-top: 10px;
  padding: 10px; /* Add padding for better spacing */
  border: 1px solid #d9534f; /* Add a border with the same color */
  border-radius: 5px; /* Rounded corners */
  background-color: #f8d7da; /* Light red background for contrast */
  transform: translateY(0px);
  position: fixed;
  top: 50%;
}

.viewEnabled {
  border-color: green;
  border-width: 5px;
}

.viewDisabled {
  border-width: 0px;
}

.rnc__notification-item {
  box-shadow: none !important;
}

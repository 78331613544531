.videoTeamView {
  top: 30%;
  height: 100%;
  background-color: #43576d;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
}

.quizSection {
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 20px;
  flex: 1;
}

.questionText {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.answerButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.answerButton {
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 15px;
  text-align: left;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
}

.answerButton:hover {
  background-color: #34495e;
}

.answerButton.correctAnswer {
  background-color: #27ae60;
}

.answerLetter {
  font-weight: bold;
  margin-right: 10px;
  font-size: 1.2em;
}

.videoTeamViewTimeLeftBar {
  width: 100%;
  height: 10px;
}

.videoTeamViewQuizResults {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.bestTeam {
  background-color: #2c3e50;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}

.showingResults {
  font-weight: bold;
  background-color: #27ae60;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}

.notShowingResults {
  font-weight: bold;
  background-color: #2c3e50;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
}

.viewComponent {
  display: flex;
}

.investmentChartContainer {
  flex: 2;
  margin-top: 20px;
  padding: 10px;
  color: white;
  background-color: #43576d;
  border-radius: 5px;
  overflow: hidden;

  flex-direction: column;
  justify-content: flex-start;
}

.investmentChart {
  width: 100%;
  top: 20%;
  left: 0%;
  /* position: relative; */
  /* background-color: black; */
}

.answersChartContainer {
  flex: 1;
  margin-top: 0px;
  padding: 0px;
  color: white;
  /* background-color: black; */
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 0px;
  top: 50%;

  display: flex;
  flex-direction: column;
}

.answersChart {
  top: 50%;
}

.recharts-label-list > * > text {
  fill: white;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  fill: white;
}

.recharts-cartesian-axis-tick {
  font-size: 1.4rem;
  font-family: Roboto, sans-serif;
  color: red;
}

.recharts-legend-item-text {
  font-size: 0.8rem;
  font-family: Roboto, sans-serif;
  color: red;
}

.viewButtons {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}

.viewButton {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border-style: none;
}

.activeViewButton {
  border-width: 10px;
  padding: 10px;
  border-style: solid;
  border-color: green;
}

.answersList {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 1.2em;
  line-height: 0.6em;
  font-style: none;
  text-align: left;
  align-self: center;
  /* padding-left: 80px; */
}

.answerListItem {
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 10px;
  line-height: 1em;
  width: fit-content;
  text-align: left;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.answersViewAnswer.CorrectAnswer {
  background-color: #27ae60;
  width: auto;
}

.answerCount {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  font-weight: bold;
}

.investmentTable .td {
  padding: 5px;
}

.investmentBreakdown {
  background-color: #2c4157;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

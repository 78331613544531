.surveyComponentContainer > div {
  scrollbar-color: white white;
}

.sd-element__content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.sd-selectbase {
  width: 100%;
  /* background-color: white; */
}

.sd-html {
  font-size: 1em !important;
}

span.agreement > p {
  line-height: 1em;
  text-align: justify;
}

span.agreementPreText {
  font-size: 1.2em !important;
  font-weight: bold;
}

.htmlTitleText {
  font-size: 1.5em !important;
  text-align: left;
  font-weight: 600 !important;
}

.htmlCaptionText {
  font-size: 1em !important;
  text-align: left;
  line-height: 1px !important;
}

.sv-string-viewer {
  font-size: 21px !important;
  text-align: left !important;
  /* font-weight: 600 !important; */
}

.sd-description > .sv-string-viewer {
  font-size: 17px;
}

.loginContainer {
  overflow-y: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #43576d;
  color: white;
}

.loginText {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.tech_logo {
  width: 120px;
  height: 120px;
  /* margin-bottom: 20px; */
  border-radius: 50%;
}

.loginText h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.loginTextFooter {
  font-size: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  max-width: 80%;
  margin: 0 auto;
}

.loginFormContainer {
  width: 100%;
  max-width: 400px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.loginFormGrid {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loginFormContainerHeader {
  font-size: 20px;
  font-weight: 600;
  color: rgba(255, 152, 20, 1);
  margin-bottom: 20px;
  text-align: center;
}

.loginFormInput {
  margin-bottom: 15px;
}

.loginFormInput input {
  background-color: #2c4157;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  height: 40px;
  font-size: 16px;
  padding: 0px;
  width: 100%;
  border-radius: 4px;
}

.loginFormInput input[type="text"] {
  box-sizing: border-box;
  padding-left: 10px;
}

.loginFormInput input[type="password"] {
  box-sizing: border-box;
  padding-left: 10px;
}

.loginFormInput input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.loginButton {
  width: 100%;
  height: 40px;
  background-color: rgba(255, 152, 20, 1);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.loginButton:hover {
  background-color: rgba(255, 152, 20, 0.8);
}

.loginButton:disabled {
  background-color: rgba(255, 152, 20, 0.5);
  cursor: not-allowed;
}

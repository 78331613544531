canvas {
  width: 100%;
  height: 100%;
  display: block;
}

.avatarImg {
  display: block;
  position: absolute;
  /* filter: blur(3px); */
  /* filter: pixelate(27px); */
  image-rendering: pixelated;
  width: 112px;
  height: auto;
  top: 17.1%;
  left: 50%;
  right: auto;
  z-index: 3;
  animation: flash 3s infinite;
  transform: translate(-50%, -50%) scale(1.45);
}

.holdText {
  position: fixed;
  /* filter: blur(2px); */
  top: calc(17% + 60px);
  left: 50%;
  transform: translateX(-50%);
  animation: flash 3s infinite;
  z-index: 5;
  border: 5px solid #2c4157;
  border-radius: 10px;
  background-color: #2c4157;
  padding: 20px;
  color: #fff;
}

.holdText.rebrand {
  background-color: #575757;
  border: 0px solid #575757;
  padding: 25px;
  border-radius: 10px;
  z-index: 6;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.holdScreen {
  background-color: #43576d;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.socialFeedContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  padding-top: 45px;
}

.socialFeedContainer > * {
  overflow-x: hidden;
}

.socialFeed {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: left;
  background-color: black;
  overflow-x: hidden;
}

.post {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid black;
  padding-bottom: 0px;
  text-align: left;
  background-color: black;
  color: white;
  overflow-x: hidden;
  max-width: 100%;
}

.post_Avatar {
  flex: 0;
  align-self: left;
}

.post_TopBar {
  display: flex;
  flex-direction: row;

  max-width: 90%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}

.post_Avatar > img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  position: relative;
  top: 25%;
}

.post_Body {
  flex: 1;
  padding: 7px;
}

.post_HeaderText {
  font-size: 18px;
  margin-left: 7px;
  line-height: 1.2em;
}

.post_HeaderText > h3 {
  margin-top: 10px;
  margin-bottom: 8px;
}

.post_Header > img {
  border-radius: 20px;
  max-width: 90%;
  max-height: 90%;
  object-fit: fill;
  padding-bottom: 8px;

  max-width: 90%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
}

.post_HeaderContent > p {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 5%;
  font-size: 20px;
}

.post_Video {
  border-radius: 20px;
  max-width: 90%;
  position: relative;
  transform: translateX(-50%);
  left: 50%;
  /* height: 100%; */
  /* max-height: 90%; */
  object-fit: fill;
  /* padding-bottom: 8px; */
}

.post_Footer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.post_Username {
  font-weight: 600;
  font-size: 18px;
  color: gray;
}

.deletePost {
  cursor: pointer;
  color: red;
  font-size: large;
  margin-left: 10px;
}

.addPostPlus {
  cursor: pointer;
  color: green;
  font-size: large;
  margin-left: 10px;
}

.verifiedBadge {
  fill: rgb(29, 155, 240);
  height: 12px;
  vertical-align: middle;
}

.top-bar.preRebrand {
  height: 40px;
  width: 100%;
  background-color: #2c4157;
  border-bottom: 3px solid rgba(255, 152, 20, 1);
}

.top-bar {
  height: 40px;
  width: 100%;
  position: fixed;
  background-color: black;
  border-bottom: 3px solid rgba(255, 152, 20, 1);
  z-index: 5;
}

.logo-container > svg {
  height: 30px;
  transform: translateY(15%);
}

.logo-container.rebrand {
  overflow: hidden;
}

.logo-container.preRebrand {
  height: 60px;
  display: inline-block;
  transform: scale(2) translateY(15px);
}

.logo-container .logo {
  height: 100%;
  width: auto;
  color: white;
}

.logo-text {
  color: white;
  font-weight: 600;
  transform: translateY(-2%);
}

.post.preRebrand {
  background-color: #202a35;
  border-bottom: 1px solid #202a35;
}

.socialFeedContainer.preRebrand {
  background-color: #202a35;
}
